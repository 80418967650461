import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const Crumb = styled.div`
  color: ${({ theme }) => theme.colors.title};
  font-size: ${({ theme }) => theme.font.headingSizes[4]};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: ${({ theme }) => theme.font.headingLineHeights[4]};
  cursor: default;
  border-radius: ${({ theme }) => theme.border.radius.medium}; // custom per design spec
  padding: 0px ${({ theme }) => theme.border.radius.medium};

  a {
    font-weight: ${({ theme }) => theme.font.weight.medium};
  }

  a:hover {
    text-decoration: none;
  }

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.body};
    text-decoration: none;
    align-items: center;
    text-decoration: none !important;
  }
`;

const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
`;

interface IntermediateBreadcrumbProps {
  label: React.ReactNode;
  url?: string;
}

export const IntermediateBreadcrumb: FC<IntermediateBreadcrumbProps> = ({ label, url }) => {
  if (url) {
    return (
      <Link to={url}>
        <Crumb>{label}</Crumb>
      </Link>
    );
  }
  return <Crumb>{label}</Crumb>;
};

export interface TectonBreadcrumbsProps {
  workspace?: React.ReactNode;
  root?: string;
  crumbs: { label: React.ReactNode; url?: string }[];
}

export const Separator = () => {
  // Ultimately, this will need to come from the (to-be-finalized) icon library
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33337 13.3337L11.6667 10.0003L8.33337 6.66699"
        stroke="#868C98"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const TectonBreadcrumbs: FC<TectonBreadcrumbsProps> = ({ workspace, root, crumbs }) => {
  const renderedCrumbs = [
    crumbs.map((crumb, index) => {
      if (index !== crumbs.length - 1) {
        return (
          <>
            <IntermediateBreadcrumb label={crumb.label} url={crumb.url} />
            <Separator />
          </>
        );
      }
      return <Crumb>{crumb.label}</Crumb>;
    }),
  ];

  return (
    <BreadcrumbsWrapper data-testid="view-breadcrumbs">
      {workspace && <Crumb>{workspace}</Crumb>}

      {root && <Crumb>{root}</Crumb>}

      {crumbs.length > 0 && (
        <>
          <Separator />
          {renderedCrumbs}
        </>
      )}
    </BreadcrumbsWrapper>
  );
};

export default TectonBreadcrumbs;
