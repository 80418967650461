import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonEmptyProps,
  EuiButtonIcon,
  EuiButtonIconProps,
  IconType,
} from '@elastic/eui';
import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { FlexGroup, FlexItem } from '@tecton/ComponentRedesign';
import React, { FC } from 'react';

export type ButtonVariant =
  | 'primaryAction'
  | 'emptyAction'
  | 'transparentAction'
  | 'dangerousAction'
  | 'disabledAction'
  | 'destructiveAction';

interface ButtonProps {
  variant?: ButtonVariant;
  label?: React.ReactNode;
  onClick?: (e: any) => void;
  iconLeft?: IconType;
  iconRight?: IconType;
  compressed?: boolean;
  icon?: IconType;
  fullWidth?: boolean;
  isLoading?: boolean;
  hint?: React.ReactNode;
  ref?: React.RefObject<HTMLDivElement>;
}

interface ButtonIconProps extends EuiButtonIconProps {
  variant?: ButtonVariant;
  onClick?: (event: React.MouseEvent) => void;
  compressed?: boolean;
  iconType: IconType;
}

const DEFAULT_VARIANT: ButtonVariant = 'primaryAction';
const DEFAULT_ICON_VARIANT: ButtonVariant = 'emptyAction';

const colorPairs = (
  theme: Theme
): Record<
  ButtonVariant,
  {
    default: { foreground: string; background: string };
    hover: { foreground: string; background: string };
  }
> => ({
  primaryAction: {
    default: {
      foreground: theme.colors.emptyShade,
      background: theme.colors.fullShade,
    },
    hover: {
      foreground: theme.colors.emptyShade,
      background: '#2A2A2C',
    },
  },
  emptyAction: {
    default: {
      foreground: theme.colors.fullShade,
      background: theme.colors.emptyShade,
    },
    hover: {
      foreground: theme.colors.fullShade,
      background: theme.colors.lightestShade,
    },
  },
  transparentAction: {
    default: {
      foreground: 'unset',
      background: 'unset',
    },
    hover: {
      foreground: 'unset',
      background: 'unset',
    },
  },
  dangerousAction: {
    default: {
      foreground: theme.colors.danger,
      background: theme.colors.emptyShade,
    },
    hover: {
      foreground: theme.colors.danger,
      background: '#FDEFEE',
    },
  },
  disabledAction: {
    default: {
      foreground: theme.colors.disabledText,
      background: theme.colors.disabled,
    },
    hover: {
      foreground: theme.colors.textDefaultSecondary,
      background: theme.colors.filledDefaultSecondaryHover,
    },
  },
  destructiveAction: {
    default: {
      foreground: theme.colors.emptyShade,
      background: theme.colors.primary,
    },
    hover: {
      foreground: theme.colors.emptyShade,
      background: '#AD0C09',
    },
  },
});

const StyledButton = styled(EuiButton)<ButtonProps>(
  ({ theme, variant = DEFAULT_VARIANT, compressed, iconLeft, iconRight }) => {
    const colors = colorPairs(theme)[variant];
    return {
      backgroundColor: colors.default.background,
      color: colors.default.foreground,
      border: `1px solid ${colors.hover.background}`,
      height: compressed ? '24px' : '32px',
      paddingLeft: iconRight ? theme.padding.l : undefined,
      paddingRight: iconLeft ? theme.padding.l : undefined,
      userSelect: 'none',
      '*': {
        textDecoration: 'none !important',
      },
      'svg *': {
        fill: 'none',
        stroke: 'currentColor',
      },
      ':hover': {
        backgroundColor: colors.hover.background,
        color: colors.hover.foreground,
        textDecoration: 'none !important',
        transition: 'none !important',
        transform: 'none !important',
      },
      span: {
        fontSize: theme.font.fontSizes.xs,
      },
      '*:hover': {
        transition: 'none !important',
      },
    };
  }
);

const StyledButtonEmpty = styled(EuiButtonEmpty)`
  color: ${({ theme }) => theme.colors.text};

  .empty-primary:active {
    background-color: unset;
  }
  padding: 0px 0px;
  .euiButtonEmpty__content {
    padding: 0px 0px;
  }
`;

const StyledIconButton = styled(EuiButtonIcon)<ButtonIconProps>(({ theme, variant = DEFAULT_VARIANT, compressed }) => {
  const colors = colorPairs(theme)[variant];

  // on hover color and border.

  return {
    backgroundColor: colors.default.background,
    border: `1px solid ${colors.default.background}`,
    color: colors.default.foreground,
    height: compressed ? '24px' : '32px',
    width: compressed ? '24px' : '32px',
    borderRadius: theme.border.radius.large,
    'svg *': {
      fill: 'none',
      stroke: 'currentColor',
    },
    span: {
      fontSize: theme.font.fontSizes.xs,
    },
    ':focus': {
      backgroundColor: colors.default.background,
    },
    ':hover': {
      backgroundColor: colors.hover.background,
      color: colors.hover.foreground,
      textDecoration: 'none',
      transition: 'none !important',
      transform: 'none !important',
    },
  };
});

export const ButtonIcon: FC<ButtonIconProps> = ({
  compressed = false,
  variant = DEFAULT_ICON_VARIANT,
  iconType,
  onClick,
  ...restProps
}) => (
  <StyledIconButton
    {...restProps}
    compressed={compressed}
    variant={variant}
    iconType={iconType}
    onClick={(event: React.MouseEvent) => {
      if (variant === 'disabledAction') {
        event.preventDefault();
        return;
      }
      onClick?.call(this, event);
    }}
  />
);

const ButtonLabel: FC<{ hint?: React.ReactNode; label: React.ReactNode }> = ({ hint = null, label }) => {
  if (hint) {
    return (
      <FlexGroup justifyContent="spaceBetween" responsive={false}>
        <FlexItem grow={0}>{label}</FlexItem>
        <FlexItem grow={0}>{hint}</FlexItem>
      </FlexGroup>
    );
  }

  return <>{label}</>;
};

export const Button: FC<ButtonProps> = ({
  variant = DEFAULT_VARIANT,
  label,
  iconLeft,
  iconRight,
  onClick,
  compressed = false,
  icon,
  fullWidth,
  isLoading,
  hint = null,
}) => {
  const iconSide = iconLeft ? 'left' : 'right';

  return (
    <StyledButton
      variant={variant}
      compressed={compressed}
      iconType={icon || iconLeft || iconRight}
      iconSide={iconSide}
      isDisabled={variant === 'disabledAction'}
      onClick={(event) => {
        if (variant === 'disabledAction') {
          event.preventDefault();
          return;
        }
        onClick?.call(this, event);
      }}
      fullWidth={fullWidth}
      data-testid="action-button"
      isLoading={isLoading}
    >
      <ButtonLabel label={label} hint={hint} />
    </StyledButton>
  );
};

export const ButtonEmpty: FC<EuiButtonEmptyProps> = (props) => {
  return <StyledButtonEmpty {...props} />;
};

export default Button;
