import { FC, useState } from 'react';
import { Tooltip, Button, ButtonVariant, SearchInput, FlexGroup, FlexItem } from '@tecton/ComponentRedesign';
import { IconType } from '@elastic/eui';

interface SearchAndActionButtonProps {
  actionButtonVariant: ButtonVariant;
  searchPlaceHolder: string;
  actionButtonLabel: string;
  onActionButtonClick: () => void;
  onSearchInputChange: (searchString: string) => void;
  leftButtonIcon?: IconType;
}

const SearchAndActionButton: FC<SearchAndActionButtonProps> = ({
  actionButtonVariant,
  searchPlaceHolder,
  actionButtonLabel,
  onActionButtonClick,
  onSearchInputChange,
  leftButtonIcon,
}) => {
  const [searchString, setSearchString] = useState<string>('');

  return (
    <FlexGroup direction="row" justifyContent="spaceBetween">
      <FlexItem>
        <SearchInput
          placeholder={searchPlaceHolder}
          value={searchString}
          fullWidth
          onChange={(event) => {
            const value = event?.target?.value;
            setSearchString(value);
            onSearchInputChange(value);
          }}
        />
      </FlexItem>
      <FlexItem grow={false} style={{ alignSelf: 'center' }}>
        {actionButtonVariant === 'disabledAction' ? (
          <>
            <Tooltip
              trigger={<Button variant={actionButtonVariant} label={actionButtonLabel} onClick={onActionButtonClick} />}
              content="Administrator Privileges Required."
            />
          </>
        ) : (
          <>
            {leftButtonIcon ? (
              <Button
                variant={actionButtonVariant}
                label={actionButtonLabel}
                onClick={onActionButtonClick}
                iconLeft={leftButtonIcon}
              />
            ) : (
              <Button variant={actionButtonVariant} label={actionButtonLabel} onClick={onActionButtonClick} />
            )}
          </>
        )}
      </FlexItem>
    </FlexGroup>
  );
};

export default SearchAndActionButton;
