import React, { ChangeEventHandler, FC } from 'react';
import styled from '@emotion/styled';
import { EuiFieldSearch, EuiFormRow } from '@elastic/eui';
import { IconTypes, Icons } from '@tecton/ComponentRedesign';

interface TectonFieldTextProps {
  label?: React.ReactNode;
  disabled?: boolean;
  message?: React.ReactNode;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  fullWidth?: boolean;
  autoFocus?: boolean;
  canUseFormRow?: boolean;
}

const StyledFieldSearch = styled(EuiFieldSearch)<{ marginBottom?: string }>`
  border-radius: ${({ theme }) => theme.border.radius.medium};
  outline: none;
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  padding: ${({ theme }) => theme.padding.m} 36px;
  margin: ${({ theme, marginBottom }) => marginBottom ?? theme.padding.xs} 0px;
  border: ${({ theme }) => theme.border.thin};

  height: 32px;
  outline: none !important;
  transition: none;
  box-shadow: none !important;
  background-image: none !important;
  background-size: none !important;
  background-color: none !important;
  border: ${({ theme }) => theme.border.thin} !important;
  color: ${({ theme }) => theme.colors.subduedText};
`;

const LabelWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  color: ${({ theme }) => theme.colors.title};
  font-weight: ${({ theme }) => theme.font.weight.regular};
`;

const MessageWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  color: ${({ theme }) => theme.colors.subduedText};
`;

const OverallWrapper = styled.div`
  padding: 0;
  margin: 0;

  .euiFormControlLayoutIcons--right {
    display: none;
  }

  .euiFormControlLayoutIcons--left {
    display: none;
  }
`;

const IconInsert = styled.div`
  position: absolute;
  top: 10px;
  left: 12px;

  circle,
  path {
    stroke: ${({ theme }) => theme.colors.subduedText};
    fill: none;
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;

  circle,
  path {
    stroke: ${({ theme }) => theme.colors.subduedText};
    fill: none;
  }
`;

const SearchWrapper = styled.div`
  position: relative;

  .euiFormControlLayout {
    height: unset;
  }
`;

const TectonSearchInput: FC<TectonFieldTextProps> = ({
  disabled,
  label,
  message,
  placeholder,
  onChange,
  value,
  fullWidth,
  autoFocus = false,
  canUseFormRow = true,
}) => {
  return (
    <OverallWrapper>
      {canUseFormRow ? (
        <>
          <EuiFormRow
            label={<LabelWrapper>{label}</LabelWrapper>}
            helpText={<MessageWrapper>{message}</MessageWrapper>}
          >
            <div style={{ position: 'relative' }}>
              <StyledFieldSearch
                data-testid="form-search-input"
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onInput={onChange}
                fullWidth={fullWidth}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
              />
              <IconInsert>{Icons[IconTypes.MAGNIFYING_GLASS]}</IconInsert>
            </div>
          </EuiFormRow>
        </>
      ) : (
        <>
          <SearchWrapper>
            <StyledFieldSearch
              data-testid="form-search-input"
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              onInput={onChange}
              fullWidth={fullWidth}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              marginBottom={'0px'}
            />
            <SearchIcon>{Icons[IconTypes.MAGNIFYING_GLASS]}</SearchIcon>
          </SearchWrapper>
        </>
      )}
    </OverallWrapper>
  );
};

export default TectonSearchInput;
