import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Workspace } from './SharedComponentTypes';
import { FlexGroupWrapper, LinkTextWrapper } from './StyledComponents';

// @tecton
import { FlexItem, Icon } from '@tecton/ComponentRedesign';

// @svg
import LiveWorkspace from '@svg/live-workspace.svg';
import DevelopmentWorkspace from '@svg/development-workspace.svg';

interface WorkspaceRootBreadcrumbProps {
  workspace?: Workspace;
}

const LiveWorkspaceIcon = <Icon type={LiveWorkspace} />;
const DevelopmentWorkspaceIcon = <Icon type={DevelopmentWorkspace} />;

const WorkspaceRootBreadcrumb: FC<WorkspaceRootBreadcrumbProps> = ({ workspace }) => {
  const icon = workspace?.workspaceType === 'Live' ? LiveWorkspaceIcon : DevelopmentWorkspaceIcon;

  return (
    <LinkTextWrapper>
      <Link to={`/repo/${workspace?.name}/home/dataflow`}>
        <>
          <FlexGroupWrapper gap="xs" direction="row" alignItems="center">
            <FlexItem>{icon}</FlexItem>
            <FlexItem>{workspace?.name}</FlexItem>
          </FlexGroupWrapper>
        </>
      </Link>
    </LinkTextWrapper>
  );
};

export default WorkspaceRootBreadcrumb;
