import React from 'react';
import { TectonGlobalStyles } from '../../_eui_components';
import { ThemeProvider, useTheme } from '@emotion/react';
import DEFAULT_THEME from './ThemeDefault';
import GlobalStyles from './GlobalStyles';
import TECTON_THEME from './ThemeTecton';
import _merge from 'lodash/merge';

const TectonTheme = _merge(DEFAULT_THEME, TECTON_THEME);

export const useTectonTheme = () => {
  const theme = useTheme();

  return { theme };
};

const TectonThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={TectonTheme}>
      <TectonGlobalStyles />
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default TectonThemeProvider;
