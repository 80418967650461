export enum ConfigurationType {
  DATABRICKS = 'DATABRICKS',
  SNOWFLAKE = 'SNOWFLAKE',
}

export enum ConfigurationStatusType {
  INTERNAL_SPARK_CLUSTER_STATUS_UNSPECIFIED = 'UNKNOWN',
  INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY = 'HEALTHY',
  INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY = 'UNHEALTHY',
  INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER = 'CREATING_CLUSTER',
  INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START = 'WAITING_FOR_CLUSTER_TO_START',
  INTERNAL_SPARK_CLUSTER_STATUS_NO_CLUSTER = 'NO_CLUSTER',
}
