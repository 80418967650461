import React, { FC } from 'react';
import { useTectonTheme } from '../Theme/ThemeProvider';
import styled from '@emotion/styled';

import { ReactComponent as AccountsAndAccess } from './Icons/AccountsAndAccess.svg';
import { ReactComponent as ArrowUpRight } from './Icons/ArrowUpRight.svg';
import { ReactComponent as Book } from './Icons/Book.svg';
import { ReactComponent as Calendar } from './Icons/Calendar.svg';
import { ReactComponent as Checkmark } from './Icons/Checkmark.svg';
import { ReactComponent as ChevronDown } from './Icons/ChevronDown.svg';
import { ReactComponent as CloseSmall } from './Icons/CloseSmall.svg';
import { ReactComponent as Columns } from './Icons/Columns.svg';
import { ReactComponent as Compute } from './Icons/Compute.svg';
import { ReactComponent as Copy } from './Icons/Copy.svg';

import { ReactComponent as Danger } from './Icons/Danger.svg';
import { ReactComponent as Dataflow } from './Icons/Dataflow.svg';
import { ReactComponent as DataQuality } from './Icons/DataQuality.svg';
import { ReactComponent as Dataset } from './Icons/Dataset.svg';
import { ReactComponent as Documentation } from './Icons/Documentation.svg';
import { ReactComponent as DrawerHide } from './Icons/DrawerHide.svg';
import { ReactComponent as DrawerShow } from './Icons/DrawerShow.svg';
import { ReactComponent as Edit } from './Icons/Edit.svg';
import { ReactComponent as Entities } from './Icons/Entities.svg';
import { ReactComponent as Eye } from './Icons/Eye.svg';
import { ReactComponent as FeatureView } from './Icons/FeatureView.svg';
import { ReactComponent as FeatureService } from './Icons/FeatureService.svg';
import { ReactComponent as Filters } from './Icons/Filters.svg';
import { ReactComponent as Folder } from './Icons/Folder.svg';
import { ReactComponent as Group } from './Icons/Group.svg';
import { ReactComponent as Information } from './Icons/Information.svg';
import { ReactComponent as KeyChain } from './Icons/KeyChain.svg';
import { ReactComponent as Jobs } from './Icons/Jobs.svg';
import { ReactComponent as LogOut } from './Icons/LogOut.svg';
import { ReactComponent as MagnifyingGlass } from './Icons/MagnifyingGlass.svg';
import { ReactComponent as Monitoring } from './Icons/Monitoring.svg';
import { ReactComponent as Permissions } from './Icons/PermissionsIcon.svg';
import { ReactComponent as Robot } from './Icons/Robot.svg';
import { ReactComponent as Sources } from './Icons/Sources.svg';
import { ReactComponent as Support } from './Icons/Support.svg';
import { ReactComponent as Tag } from './Icons/Tag.svg';
import { ReactComponent as Transformations } from './Icons/Transformations.svg';
import { ReactComponent as Trash } from './Icons/Trash.svg';
import { ReactComponent as Warning } from './Icons/Warning.svg';

type IconType =
  | 'accountsAndAccess'
  | 'arrowUpRight'
  | 'book'
  | 'calendar'
  | 'checkmark'
  | 'chevronDown'
  | 'closeSmall'
  | 'columns'
  | 'compute'
  | 'copy'
  | 'danger'
  | 'dataflow'
  | 'dataQuality'
  | 'dataset'
  | 'documentation'
  | 'drawerHide'
  | 'drawerShow'
  | 'drawerHide'
  | 'edit'
  | 'entities'
  | 'eye'
  | 'featureView'
  | 'featureService'
  | 'filters'
  | 'folder'
  | 'group'
  | 'information'
  | 'keyChain'
  | 'jobs'
  | 'logout'
  | 'magnifyingGlass'
  | 'monitoring'
  | 'permissions'
  | 'robot'
  | 'sources'
  | 'support'
  | 'tag'
  | 'transformations'
  | 'trash'
  | 'warning';

type IconSize = 's' | 'm' | 'l';

interface IconProps {
  icon: IconType;
  type: 'mono' | 'color';
  size: IconSize;
}

const Icons: Record<IconType, React.ReactNode> = {
  accountsAndAccess: <AccountsAndAccess />,
  arrowUpRight: <ArrowUpRight />,
  book: <Book />,
  calendar: <Calendar />,
  checkmark: <Checkmark />,
  chevronDown: <ChevronDown />,
  closeSmall: <CloseSmall />,
  columns: <Columns />,
  compute: <Compute />,
  copy: <Copy />,
  danger: <Danger />,
  dataflow: <Dataflow />,
  dataQuality: <DataQuality />,
  dataset: <Dataset />,
  documentation: <Documentation />,
  drawerHide: <DrawerHide />,
  drawerShow: <DrawerShow />,
  edit: <Edit />,
  entities: <Entities />,
  eye: <Eye />,
  featureView: <FeatureView />,
  featureService: <FeatureService />,
  filters: <Filters />,
  folder: <Folder />,
  group: <Group />,
  information: <Information />,
  keyChain: <KeyChain />,
  jobs: <Jobs />,
  logout: <LogOut />,
  magnifyingGlass: <MagnifyingGlass />,
  monitoring: <Monitoring />,
  permissions: <Permissions />,
  robot: <Robot />,
  support: <Support />,
  tag: <Tag />,
  transformations: <Transformations />,
  trash: <Trash />,
  warning: <Warning />,
  sources: <Sources />,
};

const IconSizeMap: Record<IconSize, string> = {
  s: '16px',
  m: '24px',
  l: '48px',
};

const IconWrapper = styled.div<{ color: string; size: IconSize }>`
  display: inline-block;
  line-height: 0;

  width: ${({ size }) => {
    return IconSizeMap[size];
  }};
  height: ${({ size }) => {
    return IconSizeMap[size];
  }};

  svg {
    width: 100%;
    height: 100%;
  }

  path,
  circle,
  ellipse,
  rect {
    stroke: ${({ color }) => {
      return color;
    }};
  }
`;

const NewIcon: FC<IconProps> = ({ icon, type = 'mono', size = 's' }) => {
  const { theme } = useTectonTheme();

  const customIconColors = new Map<IconType, string>([
    ['dataset', theme.FCOColors.dataset],
    ['entities', theme.FCOColors.entity],
    ['featureView', theme.FCOColors.featureView],
    ['featureService', theme.FCOColors.featureService],
    ['transformations', theme.FCOColors.transformation],
  ]);

  const stroke = type === 'mono' ? theme.colors.text : customIconColors.get(icon) ?? theme.colors.primary;

  return (
    <IconWrapper color={stroke} size={size} theme={theme}>
      {Icons[icon]}
    </IconWrapper>
  );
};

export default NewIcon;
