// This belongs in shared

import { FC } from 'react';
import { FCOType } from '../../../core/types/fcoTypes';
import { useTectonTheme, IconAndTooltip, IconTypes, Icons } from '@tecton/ComponentRedesign';

interface TectonFCOProps {
  type: FCOType;
  name: string;
  description?: string;
  isModified?: boolean;
}

const TectonFCOIconAndName: FC<TectonFCOProps> = ({ type, name, description, isModified }) => {
  const { theme } = useTectonTheme();

  let icon = <></>;
  let color = theme.colors.title;

  switch (type) {
    case FCOType.DATASET:
      icon = Icons[IconTypes.DATASET];
      color = theme.FCOColors.dataset;
      break;
    case FCOType.DATA_SOURCE:
      icon = Icons[IconTypes.SOURCES];
      color = theme.FCOColors.source;
      break;
    case FCOType.ENTITY:
      icon = Icons[IconTypes.ENTITIES];
      color = theme.FCOColors.entity;
      break;
    case FCOType.FEATURE_SERVICE:
      icon = Icons[IconTypes.FEATURE_SERVICE];
      color = theme.FCOColors.featureService;
      break;
    case FCOType.FEATURE_VIEW:
      icon = Icons[IconTypes.FEATURE_VIEWS];
      color = theme.FCOColors.featureView;
      break;
    case FCOType.TRANSFORMATION:
      icon = Icons[IconTypes.TRANSFORMATIONS];
      color = theme.FCOColors.transformation;
  }

  return (
    <IconAndTooltip icon={icon} name={name} highlightColor={color} description={description} isFaded={isModified} />
  );
};

export default TectonFCOIconAndName;
